<template>
  <div>
    <!-- Controllers -->
    <div
      color="white"
      class="d-flex justify-end py-4 pr-6"
    >
      <v-btn
        color="primary"
        type="submit"
        class="mr-3"
        dark
        depressed
        @click.prevent="$store.getters['dynamicForm/allFieldsFilledOut']
          ? onUpdateAction()
          : $confirm(
            $t('project.form.noFilled'), { buttonFalseText: '', buttonTrueText: $i18n.t('common.ok') }
          ).then(() => scrollToNonValidatedFields())"
        v-text="$t('common.save')"
      />
    </div>
    <!-- Body -->
    <v-container class="px-6 pt-0 pb-6 bigScreenForm">
      <!-- Tabs -->
      <v-row>
        <v-col cols="8">
          <v-tabs v-model="tab" color="secondary">
            <v-tabs-slider color="primary" />
            <v-tab v-for="{ id, name } in tabs" :key="id" class="text-capitalize" v-text="name" />
          </v-tabs>
        </v-col>
        <v-col cols="4" class="d-flex justify-end">
          <v-row
            no-gutters
            style="flex: initial !important;"
            class="align-center"
            @click="$store.commit('dynamicForm/updateField', { page: numberOfPages + 1 })"
          >
            <v-btn
              icon
              x-small
              color="primary"
            >
              <v-icon
                dark
              >
                add_box
              </v-icon>
            </v-btn>
            <span
              class="ml-2 primary--text"
            >
              {{ $t(`project.form.addPage`) }}
            </span>
          </v-row>
        </v-col>
      </v-row>
      <v-divider />
      <keep-alive v-for="(page, i) in tabs" :key="i">
        <project-dynamic-form-body
          v-if="tab === i"
          :pages="defaultPages"
          :current-page="page.id"
          :fields="fieldsByPage"
          :is-published="isPublished"
          @removePage="deletePage"
        />
      </keep-alive>
    </v-container>
    <confirm-save-dialog
      v-if="nextRoute && open"
      @close-dialog="open = false"
      @navigate="navigate"
    />
    <warning-modify-dialog
      v-if="showWarningModifyDialog"
      @confirm-modify="showWarningModifyDialog = false; update()" @close-dialog-warning-modify="showWarningModifyDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'ProjectDynamicForm',
  components: {
    ProjectDynamicFormBody: () => import('@/modules/dynamicForm/DynamicFormBody'),
    ConfirmSaveDialog: () => import('@/components/ConfirmSaveDialog'),
    WarningModifyDialog: () => import('@/modules/dynamicForm/WarningModifyDialog'),
  },
  mixins: [formUtils],
  beforeRouteLeave(to, from, next) {
    if (this.formWasModified) {
      if (this.userAgreedToLeave) {
        next()
      } else {
        this.open = true
        this.nextRoute = to
      }
    } else {
      next()
    }
  },
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
  },
  data() {
    return {
      tab: 0,
      defaultPages: 0,
      formWasModified: false,
      open: false,
      nextRoute: null,
      userAgreedToLeave: false,
      showWarningModifyDialog: false,
    }
  },
  computed: {
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters({ form: 'dynamicForm/data' }),
    ...mapGetters({ isPublished: 'project/isPublished' }),
    numberOfPages({ form }) {
      return this.uniquePages(form) || 1
    },
    tabs({ numberOfPages }) {
      return [...Array(numberOfPages)].map((_, i) => ({
        id: i + 1,
        name: this.$t('common.page', { number: i + 1 }),
      }))
    },
    fieldsByPage({ form, tab }) {
      return form?.fields?.filter(field => field.page === (tab + 1))
    },
  },
  watch: {
    numberOfPages(num) {
      this.tab = num - 1
    },
    'form.fields': function (newValue, oldValue) {
      this.formWasModified = !!oldValue
    },
  },
  async created() {
    const { organizationId, projectId } = this
    if (!this.form.fields) this.$store.commit('dynamicForm/updateField', { page: 1 })
    this.defaultPages = this.uniquePages(this.form)
  },
  methods: {
    uniquePages(form) {
      const fieldsPages = form?.fields?.map(field => field.page)
      return new Set(fieldsPages).size
    },
    onUpdateAction() {
      if (this.isPublished) {
        this.showWarningModifyDialog = true
        return
      }
      this.update()
    },
    update() {
      const { scrollToNonValidatedFields, organizationId, projectId } = this
      if (this.allFieldsNoEmpty()) {
        this.$store.commit('dynamicForm/changeFilledState', true)
        this.runAsync(async () => {
          this.$store.dispatch('dynamicForm/updateForm', { organizationId, projectId })
        })
      } else {
        this.$store.commit('dynamicForm/changeFilledState', false)
        scrollToNonValidatedFields()
        return
      }
      this.userAgreedToLeave = true
    },
    scrollToNonValidatedFields() {
      const { form } = this
      const firstInvalidField = Object.values(form.fields).find(
        field => !field?.type
          || (!field?.label?.length && !field?.text?.length)
          || field?.options?.some(option => !option?.label),
      )
      if (firstInvalidField) {
        const { id, page } = firstInvalidField
        this.tab = page - 1
        this.$nextTick(() => {
          const missingInput = document.getElementById(id)
          const y = missingInput.getBoundingClientRect().top - 64
          window.scroll({ top: y, behavior: 'smooth' })
        })
      }
    },
    deletePage(pageId) {
      const { organizationId, projectId } = this.project
      this.runAsync(async () => {
        this.$store.dispatch('dynamicForm/deletePage', { organizationId, projectId, pageId })
      })
      if (this.allFieldsNoEmpty()) {
        this.$store.commit('dynamicForm/changeFilledState', true)
      } else {
        this.$store.commit('dynamicForm/changeFilledState', false)
      }
    },
    navigate() {
      this.userAgreedToLeave = true
      this.open = false
      this.$router.push({ path: this.nextRoute.path })
    },
    allFieldsNoEmpty() {
      return Object
        .values(this.form.fields)
        .every(field => field?.type // only consider initialized fields
          && (field?.label?.length || field?.text?.length)) // all fields should have a label or text
    },
  },
}
</script>

<style scoped>
.primary--text {
  cursor: pointer;
}
</style>
